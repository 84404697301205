function Header() {
    return (
        <div>
            <div className="container-fluid text-center">
                <h1 class="pt-5">Pig Latin Translator</h1>
            </div>
        </div>
    );
}

export default Header;
